<template>
  <ui-component-modal
    modalTitle=""
    :isSaving="isSaving"
    :isSavingSuccess="isSavingSuccess"
    :isSavingError="isSavingError"
    :hideFooterButtons="isSavingError || isSavingSuccess"
    :onClickCancel="onClickCancel"
    :onClickSave="saveVoucher"
    :showModal="showModal"
    @closeModal="onClickCancel"
  >
    <template slot="modalTitle"></template>
    <template v-slot:content>
      <table class="table is-fullwidth is-narrow">
        <thead>
          <tr>
            <th width="50">
              {{ $t('Components.Spaces.ModalEditSpaceChannels.Table_Active') }}
            </th>
            <th>
              {{ $t('Components.Spaces.ModalEditSpaceChannels.Table_Channel') }}
            </th>
            <th>
              {{ $t('Components.Spaces.ModalEditSpaceChannels.Table_Public') }}
            </th>
          </tr>
        </thead>
        <tbody
          name="fade"
          is="transition-group"
          mode="out-in"
          :duration="{ enter: 300, leave: 300 }"
        >
          <tr v-for="channel in editChannels" :key="channel.ChannelId">
            <td width="50">
              <input
                type="checkbox"
                v-model="channel.IsActive"
                :checked="channel.IsActive"
              />
            </td>
            <td>
              <span>{{ channel.ChannelName }}</span>
            </td>
            <td>
              <input
                type="checkbox"
                v-model="channel.IsPublic"
                :disabled="!channel.IsActive"
                :checked="channel.IsPublic"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </template>
  </ui-component-modal>
</template>

<script>
import Vue from 'vue'
import { mapState, mapMutations } from 'vuex'
import { EventBus } from '@/eventbus/event-bus'
import voucherProvider from '@/providers/voucher'

export default {
  components: {},

  props: {
    showModal: {
      type: Boolean,
      default: false,
    },

    onClickCancel: {
      type: Function,
      required: true,
    },

    channelId: {
      type: Number,
      required: true,
    },

    locationId: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      editChannels: [],
      isSaving: false,
      isSavingSuccess: false,
      isSavingError: false,
    }
  },

  computed: {
    ...mapState('channelStore', ['channel']),
    ...mapState('locationStore', ['locationState']),
    ...mapState('voucherStore', ['voucher']),
  },

  created() {
    this.mVoucher = JSON.parse(JSON.stringify(this.voucher))

    this.locationChannels = this.getLocationChannels()

    this.prepData()
  },

  methods: {
    ...mapMutations('voucherStore', ['setVoucher']),

    getLocationChannels() {
      let locationChannnels = []
      if (this.locationState) {
        locationChannnels = this.locationState.Channels
      }
      return locationChannnels
    },

    checkVoucherOnChannel(channelId) {
      let result = false

      let channelIndex = this.mVoucher.Channels.findIndex(
        (c) => c.ChannelId === channelId
      )
      if (channelIndex > -1) {
        result = true
      }

      return result
    },

    checkVoucherActiveOnChannel(channelId) {
      let result = false

      let channelIndex = this.mVoucher.Channels.findIndex(
        (c) => c.ChannelId === channelId
      )
      if (channelIndex > -1) {
        result = this.mVoucher.Channels[channelIndex].IsPublic
      }

      return result
    },

    prepData() {
      let self = this

      self.editChannels.push({
        ChannelId: self.channel.Id,
        ChannelName: self.channel.Name,
        IsPublic: self.checkVoucherActiveOnChannel(self.channel.Id),
        IsActive: self.checkVoucherOnChannel(self.channel.Id),
      })
    },

    saveVoucher() {
      let self = this

      if (!self.isSaving) {
        self.isSaving = true

        self.mVoucher.ChannelId = self.channelId
        self.mVoucher.LocationId = self.locationId
        self.mVoucher.Channels = self.editChannels.filter((c) => c.IsActive)

        voucherProvider.methods
          .saveVoucher(self.mVoucher)
          .then((response) => {
            if (response.status === 200) {
              self.setVoucher(response.data)
              self.isSavingSuccess = true

              setTimeout(() => {
                self.$emit('voucherEdited', response.data)
                self.onClickCancel()
              }, 1500)
            }
          })
          .catch((error) => {
            self.isSavingError = true
          })
          .finally(() => {
            self.isSaving = false
          })
      }
    },
  },
}
</script>
